<template>
  <div>
    <PageHeader main-header="nav-bar.summary" />
    <div class="sticky-el--menu">
      <v-tabs show-arrows>
        <v-tab
          :to="{ name: 'summary-type', params: { type: 'daily' } }"
          @click="$helper.scrollTop"
          >{{ $t('summary.daily') }}</v-tab
        >
        <v-tab
          :to="{ name: 'summary-type', params: { type: 'weekly' } }"
          @click="$helper.scrollTop"
          >{{ $t('summary.weekly') }}</v-tab
        >
        <v-tab
          :to="{ name: 'summary-type', params: { type: 'monthly' } }"
          @click="$helper.scrollTop"
          >{{ $t('summary.monthly') }}</v-tab
        >
      </v-tabs>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'

export default {
  name: 'Summary',

  components: {
    PageHeader
  }
}
</script>

<style scoped></style>
